.community{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.community-text{
    font-size: 16px;
    width: 80%;
    font-family: var(--poppins-font);
}

.community-text a{
    color: var(--primary-color);
    font-weight: 600;
    transition: all 0.5;
}

.community-text a:hover{
    cursor: pointer;
    text-decoration: underline;
}

.community-image{
    height: 300px;
    object-fit: cover;
    margin: 2rem 0;
}

.community-image img{
    width: 100%;
    height: 100%;
    background-size: cover;
}

@media screen and (max-width: 525px) {
    .community-text{
        width: 100%;
    }
}

@media screen and (max-width: 420px) {
    .community-image{
        height: 200px;
    }
}