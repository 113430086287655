/* General Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Header styles */
.header {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(0deg, rgba(0, 0, 15, 0.658) 75%, rgba(0, 0, 15, 0.9));
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: auto;
  overflow: hidden;
}

/* Swiper container */
.swiper_container {
  width: 100%;
  height: 100%;
}

/* Slide text */
.carousel-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(24, 22, 22);
  z-index: 10;
  text-align: center;
  font-size: 20px;
  transition: transform 0.5s ease;
}

.carousel-text h2 {
  font-size: 4rem;
  margin-bottom: 10px;
  color: rgb(10, 10, 11);
  font-family: New Century Schoolbook, TeX Gyre Schola, serif;
}

.carousel-text p {
  font-size: 1.2rem;
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 768px) {
  .carousel-text {
    transform: translate(-50%, -50%);
    width: 80%; /* Ensures text doesn't overflow */
  }

  .carousel-text h2 {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }

  .carousel-text p {
    font-size: 1rem; /* Adjust paragraph size for smaller screens */
  }

  .swiper-slide img {
    width: 100vw; /* Ensures images fit the mobile width */
    height: auto; /* Let the height adjust based on width */
  }
}

/* Media Query for Smaller Mobile Screens */
@media only screen and (max-width: 480px) {
  .carousel-text h2 {
    font-size: 1.5rem; /* Further reduce heading size */
  }

  .carousel-text p {
    font-size: 0.9rem; /* Further reduce paragraph size */
  }
}

/* Zoom effect on hover */
.swiper-slide:hover .carousel-text {
  transform: scale(1.1) translate(-50%, -50%);
}

/* Blur background and style for text */
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .carousel-text {
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(90px);
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 80%;
    border-radius: 25px;
  }
}
