/* EventList.css */

.event-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.event {
    background-color: transparent;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 16px 3px 7px 0px rgb(0 0 0 / 31%)
   
}

.event-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.event-desc {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.event-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    margin-top: 1rem;
}

.event-link:hover {
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    .event {
        padding: 1rem;
    }
}
