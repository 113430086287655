.client-testimonials {
  padding: 2rem;
  text-align: center;
}

.section-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 2rem;
}

.testimonial-slider {
  max-width: 800px;
  margin: 0 auto;
}

.testimonial {
  background-color: transparent; /* Initial background color */
  border-radius: 8px;
  padding: 8rem; /* Adjust padding */
  text-align: left;
  position: relative; /* Position relative for absolute positioning of the image */
  margin-bottom: 2rem; /* Margin between testimonials */
  border: 2px solid transparent; /* Initial border */
  transition: all 0.3s ease-in-out; /* Smooth transition for background, border, and size changes */
}

.testimonial:hover {
  background-color: #fff; /* White background on hover */
  border-color: #2469bc; /* Visible border color on hover */
  
  transform: scale(1.05); /* Slightly enlarge the slide on hover */
  border-radius: 20px;
}



.testimonial-text {
  font-style: italic;
  font-size: 1.2rem;
  color: #333; /* Adjust text color for better visibility on white background */
  margin-bottom: 1rem;
  padding-left: 2rem;
}

.testimonial-author {
  font-size: 1.1rem;
  font-weight: bold;
  color: #2469bc; /* Adjust text color */
}

.testimonial-company {
  font-size: 1rem;
  color: #777; /* Adjust text color */
}

.client-image {
  width: 80px; /* Default size for larger screens */
  height: 80px;
  border-radius: 50%;
  position: relative;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .testimonial {
    padding: 1.5rem; /* Adjust padding for smaller screens */
  }

  .client-image {
    width: 20px; /* Reduce the size of client image on mobile */
    height: 20px; /* Maintain a square ratio */
    top: 0; /* Adjust positioning for smaller screens */
    margin-bottom: 1rem; /* Ensure adequate space between image and text */
  }

  .section-heading {
    font-size: 2rem; /* Adjust heading for smaller screens */
  }

  .testimonial-text {
    font-size: 1.1rem; /* Adjust text size for better readability on small screens */
  }

  .testimonial-author {
    font-size: 1rem; /* Slightly reduce the font size for mobile */
  }

  .testimonial-company {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }

  .testimonial-slider {
    width: 100%; /* Ensure slider width fits the screen */
  }
}


.swiper-button-next, .swiper-button-prev {
  background-color: transparent;
  color: #2469bc; /* Color for navigation buttons */
}
