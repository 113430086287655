h1{
    text-align: center;
    font-family: var(--poppins-font);
    font-weight: 700;
    margin: 4rem auto 1rem;
    color:white;
}

.job-apply_form{
    margin: 1rem auto 3rem;
    width: 60%;
    background-color: #fff;
    padding: 1rem;
}

.input-field{
    font-family: var(--poppins-font);
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
}

/* .input-field label{

} */

.input-field input,
.input-field .passout{
    width: 50%;
    padding: 6px 8px;
    font-size: 16px;
    border: 1px solid gray;
    outline: none;
    border-radius: 5px;
}

.passout{
    margin: 0;
}

.btn{
    background: linear-gradient(180deg, var(--primary-color), var(--secondary-color));
    color: white;
    cursor: pointer;
    padding: 1rem 2rem;
    border: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
}

@media screen and (max-width: 525px) {
    .job-apply_form{
        flex-direction: column;
    }
}