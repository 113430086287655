/* Achievements.css */

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.section-title {
  text-align: center;
  margin-bottom: 2rem;
  
}

.section-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 2rem;
  
}

.section-subheading {
  font-size: 1.5rem;
  color: #666;
  margin-top: 0;
} 

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-in, transform 0.5s ease;
}


.about-content.show {
  opacity: 1;
  transform: translateY(0);
}
.about-image {
  width: 100%;
  max-width: 400px; /* Adjust the maximum width of the image */
  margin-bottom: 2rem;
}

.about-image img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: Add border radius for rounded corners */
  transition: filter 0.3s ease, transform 0.3s ease; /* Add transition effects */
}

.about-image img:hover {
  cursor: pointer;
  filter: brightness(80%); /* Adjust brightness on hover */
  transform: scale(1.1);
}

.about-text {
  margin-top: 0px;
  max-width: 600px; /* Adjust the maximum width of the text */
  text-align: justify; /* Optional: Justify text for better readability */
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem; /* Add padding around text */
  margin-bottom: 30px;
  background-color:transparent; /* Add background color */
  border-radius: 8px; /* Add border radius */
}

.about-text p {
  font-size: 1rem; /* Adjust font size as needed */
  line-height: 1.6; /* Adjust line height for better readability */
  color: #B6EADA;
}

/* Button styles */
.about-text button {
  position: relative;
  border: none;
  border-radius: 150px;
  background-color: #fff; /* Change button background color */
  color: #007bff; /* Change button text color */
  padding: 0.75rem 1.5rem; /* Adjust button padding */
  font-size: 1rem; /* Adjust button font size */
  cursor: pointer; 
  width: 200px;
  overflow: hidden; /* Hide overflow for pseudo-elements */
  z-index: 1; /* Ensure the button text is on top */
  transition: background-color 0.3s ease, color 0.3s ease; /* Add transition effect */
}

/* Pseudo-element for bubble effect */
.about-text button::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  background-color: rgba(188, 184, 243, 0.8); /* Bubble color */
  width: 0; /* Initial size */
  height: 0; /* Initial size */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.5s ease, height 0.5s ease, background-color 0.5s ease; /* Transition for the bubble effect */
  z-index: -1; /* Ensure pseudo-element is behind the button text */
}

/* Bubble hover effect */
.about-text button:hover::before {
  width: 600px; /* Final size */
  height: 600px; /* Final size */
  background-color: rgba(115, 94, 234, 0.8); /* Change color if needed */
}


 

@media screen and (min-width: 768px) {
  .about-content {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .about-text {
    margin-left: 2rem; /* Adjust spacing between image and text */
  }
}

@media screen and (max-width: 768px) {
  .about-text {
    padding: 0 1rem; /* Adjust padding for smaller screens */
  }
}

