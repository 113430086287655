.section{
    background: url(../../assets//img/testimonial-circle.png) center no-repeat;
    background-size: contain;
    padding: 4rem 0 0;
}

.heading{
    text-align: center;
    margin: 0 auto;
    padding: 1rem;
    font-family: var(--poppins-font);
    background: linear-gradient(#7700fff3, #2100f9b6, #7700fff3);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
}

.testimonials{
    width: 100%;
    padding: 2rem 0;
    position: relative;
}

.svgImage{
    position: absolute;
    top: 60%;
    left: 10%;
}

.testimonial{
    width: 70%;
    min-height: 440px;
    padding: 3rem 2rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    background: linear-gradient(0deg, rgba(255,255,255,0.5), rgba(255,255,255,0.8));
    border-radius: 25px;
    position: relative;
}

.testimonial:hover{
    box-shadow: 10px 8px 25px 10px rgba(0,0,0,0.05);
    transform: scale(1.01);
    background-color: #fff;
}

.testimonial span{
    height: 6em;
    width: 6em;
    background: linear-gradient(360deg, var(--secondary-color), var(--primary-color));
    border-radius: 50%;
    position: relative;
  }

.testimonial .avatar{
    content: '';
    position: absolute;
    height: 90%;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.testimonial h2{
    color: var(--primary-color);
    font-family: var(--poppins-font);
    letter-spacing: 1.1px;
    text-align: center;
}

.testimonial h4{
    text-align: center;
    color: var(--secondary-color);
    font-family: var(--opensans-font);
}

.testimonial p{
    text-align: justify;
    line-height: 32px;
    padding-bottom: 1rem;
    letter-spacing: 1.1;
}

p svg{
    color: var(--tertiary-color);
}

@media screen and (max-width: 992px) {
    .testimonial{
        width: 100%;
        height: fit-content;
    }
}

@media screen and (max-width: 525px) {
    .section{
        padding-top: 2rem;
    }
    .testimonials{
        padding: 0;
    }
    .testimonial{
        height: 550px;
        padding: 1rem 2rem 0;
        justify-content: center;
    }
    .heading{
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 420px) {
    .testimonial{
        min-height: 400px;
        padding: 3rem 8px;
    }
    .testimonial p{
        font-size: 12px;
        line-height: 20px;
        padding: 0 5px;
    }
}