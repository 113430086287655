.desc{
    margin: auto 10rem;
    padding: 2rem 0 0;
}

.desc p{
    text-align: center;
}

.cards{
    margin: 3rem auto 0;
}

@media screen and (max-width: 768px) {
    .desc{
        margin: auto 4rem;
    }
}
@media screen and (max-width: 525px) {
    .desc{
        margin: auto 2rem;
    }
}