.footer-card{
    display: flex;
    flex-direction: column;
    gap: 14px;
}

/* title of the links */
.footer-card h3{
    color: var(--tertiary-color);
    border-bottom: 3px solid var(--tertiary-color);
    width: fit-content;
    font-family: var(--josefinsans-font);
    padding-bottom: 8px;
}

/* footer link */
.footer-info a{
    color: white;
    font-family: var(--poppins-font);
    font-size: 14px;
    width: fit-content;
}

.footer-info a:hover{
    color: var(--secondary-color);
}

.footer-info{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media screen and (max-width: 576px){
    .footer-info a{
        font-size: 14px;
    }
}