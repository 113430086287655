.footer-container {
    position: absolute;
    width: 100%;
    background-color: rgb(51, 51, 51);
    color: white;
}

.footer-content {
    padding: 2rem 1rem 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.cards {
    /* ... other styles */
    display: flex;
    flex-wrap: wrap; /* Wrap cards to avoid overflow */
    gap: 3rem;
  }

.card-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(160px, 1fr));
    justify-items: center;
}


.footer-container {
    /* existing styles */
  }
  
  .footer-content {
    /* existing styles */
  }
  
  .footer-columns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .footer-card {
    flex: 1;
    min-width: 200px;
    max-width: 300px;
  }
  
  .address {
    /* You may need to adjust some styles here to fit the new layout */
  }
  
  /* Rest of your existing styles */
  
  @media (max-width: 1200px) {
    .footer-columns {
      justify-content: flex-start;
    }
    
    .footer-card {
      flex-basis: calc(50% - 1rem);
      max-width: none;
    }
  }
  
  @media (max-width: 768px) {
    .footer-card {
      flex-basis: 100%;
    }
  }

.footer-card h3 {
    color: var(--tertiary-color);
    border-bottom: 3px solid var(--tertiary-color);
    width: fit-content;
    font-family: var(--josefinsans-font);
    padding-bottom: 8px;
}

.info p {
    letter-spacing: 0.5px;
}

.info-card {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 1rem 0;
}

.info-card .circle-icon {
    width: 40px;
    height: 40px;
    background-color: var(--tertiary-color);
    border-radius: 50%;
}

.info-card .icon {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 20px;
}

.info-card p {
    color: white;
    font-family: var(--poppins-font);
    font-size: 14px;
    width: 90%;
}

.address {
    grid-column: 3/5;
}

.social-media {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    gap: 1rem;
}

.social-media span {
    font-size: 2rem;
    cursor: pointer;
}

.fb,
.insta,
.linkedin {
    color: #fff;
}

.fb:hover {
    color: #1773EA;
}

.insta:hover {
    color: #D12D80;
}

.linkedin:hover {
    color: #4682b4;
}

.cards {
    display: flex;
    gap: 3rem;
    margin: 2rem;
}

@media screen and (max-width: 992px) {
    .card-container {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 2rem;
    }
    .footer-content {
        padding: 2rem 8px 0;
        flex-direction: column;
        gap: 4rem;
    }
    .footer-content .logo {
        align-self: center;
    }
}

@media screen and (max-width: 576px) {
    .cards {
        flex-direction: column;
        align-items: center;
        margin: 2rem 0;
    }
}

.copyright {
    text-align: center;
    margin: 1rem 0;
    font-size: 14px;
}
