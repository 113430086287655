.header{
    position: relative;
    width: 100%;
    height: 70vh;
}

.header::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: linear-gradient(180deg, rgba(11,11,11,0.90) 8%, transparent, rgba(11,11,11, 0.9) 90%);
}

.bgimg{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.header h1{
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    color: white;
    z-index: 99;
    text-align: center;
    letter-spacing: 1.2px;
    font-size: 45px;
    font-family: var(--montserrat-font);
}

@media screen and (max-width: 992px) {
    .header h1{
        font-size: 36px;
    }
}

@media screen and (max-width: 768px) {
    .header h1{
        width: 90%;
        font-size: 28px;
    }
}

@media screen and (max-width: 425px) {
    .header{
        height: 40vh;
    }
    .header h1{
        width: 98%;
        font-size: 22px;
    }
}