.content{
    width: 100%;
}

.about{
    padding: 3rem 6rem 0;
    text-align: center;
}

.aboutbg{
    padding: 2rem 6rem 4rem;
    background: radial-gradient(circle at 50%, #ddf1ff8f, #b6e2ff8f 70%, #68ccf0c4 90%, #68ccf0 70%);    
}

.about-cards{
    padding: 3rem 1rem;
}

.about h1{
    margin-bottom: 2rem;
    padding: 0 10rem;
    font-family: var(--poppins-font);
    color: var(--primary-color);
    background: linear-gradient(#7700fff3, #2100f9b6, #7700fff3);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
}

.cards{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.info{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.content-offer{
    text-align: left;
}

.content-offer h3{
    margin-bottom: 8px;
    font-size: 22px;
    font-family: var(--poppins-font);
}

.offers{
    font-size: 18px;
    font-family: var(--opensans-font);
}

/* .offers .bullet{
    background: url(../../assets/vector/circle.png) center no-repeat;
    height: 15px;
    width: 15px;
    border-radius: 50%;
} */

.offers .offerlist{
    list-style-image: url(../../assets/vector/list-dot.png);
    padding: 5px 0rem;
    list-style-position: inside;
}

.offerlist .sublist{
    padding: 6px 2rem;
}

.sublist li{
    list-style: disc;
    list-style-position: inside;
    padding: 2px 0;
}

.info img{
    width: 400px;
    height: 400px;
    background-position: center;
    background-size: cover;
}

.community-section{
    padding: 0 6rem 2rem;
}


/* Members section style*/

/* .membersbg{
    margin: auto 1rem;
} */

.members{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin: 4rem 1rem 0;
}

@media screen and (max-width: 525px) {
    .members{
        margin-top: 2rem;
    }
}

/* End of Members section style*/


@media screen and (max-width: 992px) {
    .about{
        padding: 3rem 1rem;
    }
}

@media screen and (max-width: 768px) {
    .about h1{
        padding: 0;
    }
}

@media screen and (max-width: 525px) {
    .about{
        padding: 2rem 1rem;
    }
    .about h1{
        font-size: 1.5rem;
    }
    .about-cards{
        padding: 3rem 0;
    }
}