.hexagon{
    padding: 2rem 0;
    width: 370px;
    height: 370px;
}

.shape{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    background:  linear-gradient(0deg, rgba(250,250,250, 0.7), rgba(209, 209, 209, 0.7));
    clip-path: polygon(20% 0, 80% 0, 100% 50%, 80% 100%, 20% 100%, 0% 50%);
    transition: 1s;
    border: 1px solid  #0B60B0;
}

.shape span{
    width: 40px;
    height: 40px;
    z-index: 9;
    padding: 6px;
    background-color: #fff;
    border-radius: 50%;
}

.shape .icon{
    position: relative;
    height: 24px;
    width: 24px;
    z-index: 9;
    top: 50%;
    transform: translateY(-50%);
}

.shape .info{
    text-align: center;
    padding: 0 1rem 1rem;
    z-index: 9;
    
}

.info h2{
    margin: 1rem auto;
    font-family: var(--nunito-font);
    color:black;
}

.info p{
    padding: 0 1rem;
    font-size: 14px;
    
}

.shape::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    transition: 0.2s;
}

.hexagon:nth-child(1) .shape::before{
    background: linear-gradient(180deg, rgba(172, 39, 255, 0.608), transparent);
}

.hexagon:nth-child(2) .shape::before{
    background: linear-gradient(180deg, rgba(125, 196, 2, 0.4), transparent);
}

.hexagon:nth-child(3) .shape::before{
    background: linear-gradient(180deg, rgba(255, 52, 106, 0.341), transparent);
}

.shape:hover::before{
    height: 100%;;
}

.hexagon:nth-child(1) .shape .icon{
    color: rgb(172, 39, 255);
}

.hexagon:nth-child(2) .shape .icon{
    color: rgb(125, 196, 2);
}

.hexagon:nth-child(3) .shape .icon{
    color: rgb(255, 52, 106);
}

@media screen and (max-width: 525px) {
    .info h2{
        margin: 8px auto;
    }
}

@media screen and (max-width: 420px) {
    .hexagon{
        width: 350px;
        height: 350px;
    }
}