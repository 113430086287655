.card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 10rem;
    margin: auto 4rem;
}

.card:nth-child(even){
    flex-direction: row-reverse;
}

.card:nth-child(odd){
    background: linear-gradient(-90deg, rgba(0, 0, 26, 0.85) 40%, rgba(0, 0, 26, 0.5) 90%, rgba(0, 0, 26, 0.2)), url(../../assets/vector/es-bg.jpg);
    color: #fff;
}

.card .content{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.card:nth-child(even) .content{
    align-items: flex-end;
}

.card .content h2{
    font-family: var(--poppins-font);
    font-weight: 500;
}

.card .content p{
    line-height: 28px;
    width: 60%;
}

.card:nth-child(even) .content p{
    text-align: justify;
}


.card-image{
    height: 180px;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
}

.card-image img{
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 992px) {
    .card .content p{
        width: 90%;
    }
    .card{
        padding: 4rem 8rem;
        margin: 0;
    }
}

@media screen and (max-width: 768px) {
    .card,
    .card:nth-child(even){
        flex-direction: column-reverse;
        padding: 2rem;
        text-align: center;
        gap: 2rem;
    }
    .card:nth-child(even) .content{
        align-items: center;
    }
    .desc{
        margin: 2rem 5rem;
    }
}

@media screen and (max-width: 525px){
    .card .content p{
        width: 100%;
        margin: 0 auto;
    }
}